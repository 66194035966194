import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/modules/material.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SitesModule } from '../sites/sites.module';
import { CustomerlistComponent } from './components/customerlist/customerlist.component';
import { CustomerRoutingModule } from './customer-routing-module';
import { CustomerService } from './services/customer.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CustomeraddComponent } from './components/customeradd/customeradd.component';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { CustomerwizardService } from './services/customerwizard.service';
import { MatSortModule } from '@angular/material/sort';
import { CustomerdetailseditComponent } from './components/customerdetailsedit/customerdetailsedit.component';
import { CustomersiloeditComponent } from './components/customersiloedit/customersiloedit.component';



@NgModule({
  declarations: [CustomerlistComponent, CustomeraddComponent, CustomerdetailseditComponent, CustomersiloeditComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        MaterialModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        SitesModule,
        MatPaginatorModule,
        MatStepperModule,
        RouterModule,
        MatSortModule
    ],
  entryComponents: [CustomerdetailseditComponent, CustomersiloeditComponent],
  providers: [CustomerService, CustomerwizardService],
  exports: [CustomerlistComponent, CustomerRoutingModule, CustomerdetailseditComponent, CustomersiloeditComponent]
})
export class CustomersModule { }
