import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog, MatSnackBarConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { AlertDialogData } from '../models/AlertData';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private readonly snackBar: MatSnackBar, public dialog: MatDialog) {

  }

  success(message: string) {
    this.snackBar.open(message, 'Ok', this.getSnackBarConfig('success-snackbar'));
  }

  danger(message: string) {
    this.snackBar.open(message, 'Ok', this.getSnackBarConfig('danger-snackbar'));
  }

  warning(message: string) {
    this.snackBar.open(message, 'Ok', this.getSnackBarConfig('warning-snackbar'));
  }

  private getSnackBarConfig(css?: string): MatSnackBarConfig {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'center';
    config.duration = 3000;
    config.panelClass = css ? css : '';
    return config;
  }


  confirm(message: string, title: string = '', okText: string = 'Ok', cancelText: string = 'Cancel',
          additionalMessages: string[] = []): Observable<any> {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: { message, title, okText, cancelText, additionalMessages },
      minHeight: 185,
      width: '500px'
    });
    return confirmDialog.afterClosed().pipe(filter(confRes => confRes === true));
  }


  alertError(message: string, title: string): Observable<any> {
    return this.alert(AlertDialogData.setError(message, title));
  }

  alertInfo(message: string, title: string): Observable<any> {
    return this.alert(AlertDialogData.setInfo(message, title));
  }

  alertWarning(message: string, title: string): Observable<any> {
    return this.alert(AlertDialogData.setWarning(message, title));
  }

  alertSuccess(message: string, title: string): Observable<any> {
    return this.alert(AlertDialogData.setSuccess(message, title));
  }

  alert(alertData: AlertDialogData): Observable<any> {
    const confirmDialog = this.dialog.open(AlertDialogComponent, {
      disableClose: false,
      data: alertData,
      minHeight: 185,
      minWidth: 370
    });
    return confirmDialog.afterClosed();
  }

}
