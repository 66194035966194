import { Component, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Site } from '../../models/Site';
import { SiteService } from '../../services/site.service';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
@Component({
  selector: 'app-site-select-list',
  templateUrl: './site-select-list.component.html',
  styleUrls: ['./site-select-list.component.scss']
})
export class SiteSelectListComponent implements OnInit {
  sites: Array<Site>;
  siteControl = new FormControl();
  filteredSites: Observable<Array<Site>>;
  loaded = false;
  @Output() selectedSite: EventEmitter<Site> = new EventEmitter<Site>();
  @Output() loadFinish: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private siteService: SiteService) {
    this.filteredSites = this.siteControl.valueChanges
    .pipe(
      startWith(''),
      map(site => site ? this._filteredSites(site) : this.sites.slice())
    );
   }

  ngOnInit() {
    this.siteService.getSites().subscribe(sites => {
      this.loaded = true;
      this.loadFinish.emit(true);
      this.sites = sites;
    });
  }

  private _filteredSites(value: string): Array<Site> {
    value = typeof value === 'object' ? '' : value;
    const filterValue = value.toLowerCase();
    return this.sites.filter((site: Site) => site.name.toLowerCase().indexOf(filterValue) === 0);
  }

  onSelected(site: Site) {
    this.siteControl.setValue(site.name);
    this.selectedSite.emit(site);
  }

}
