declare const DEPLOYMENT_CONFIG: { [key: string]: any };

export const environment = {
  production: false,
  apiBaseUrl: 'https://gbcorders-api-int.fbuapps.com/api/',
  auth0: {
    domain: 'gbcw-ordering-dev.auth.fbuapps.com',
    client_id: 'I5Uy76oNk4EA4UOEpX98GpicNv6c4pdd',
    redirect_uri: `${window.location.origin}/callback`,
    audience: 'https://dev.api.gbcw.fletcherbuilding.com'
  },
  ...(typeof DEPLOYMENT_CONFIG === 'undefined' ? {} : DEPLOYMENT_CONFIG)
};
