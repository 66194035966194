import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDisableFeatureLink]'
})
export class DisableFeatureLinkDirective {

  constructor(private elRef: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(this.elRef.nativeElement, 'disabled');
   }
}
