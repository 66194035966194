import { NgModule } from '@angular/core';
import { UserListComponent } from './components/user-list/user-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/modules/material.module';
import { UserRoutingModule } from './user-routing-module';
import { UserService } from './services/user.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { UserSiteRoleEditorComponent } from './components/user-site-role-editor/user-site-role-editor.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SitesModule } from '../sites/sites.module';
import { UserSiteAddComponent } from './components/user-site-add/user-site-add.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [UserListComponent, UserSiteRoleEditorComponent, UserSiteAddComponent],
  entryComponents: [UserSiteRoleEditorComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        MaterialModule,
        SharedModule,
        UserRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SitesModule,
        MatPaginatorModule
    ],
  exports: [UserRoutingModule, UserListComponent, UserSiteRoleEditorComponent],
  providers: [UserService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] }]
})
export class UserModule { }
