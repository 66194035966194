import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AuthService } from './Authentication/auth.service';
import { Router } from '@angular/router';
import { AlertService } from './shared/services/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading = false;
  opened = true;

  constructor(public auth: AuthService, private readonly router: Router, private readonly alertService: AlertService) {
  }

  ngOnInit(): void {
    this.handleAuth();
  }

  login(): void {
    this.loading = true;
    this.auth.login();
  }

  private handleAuth() {
    this.loading = true;
    this.auth.isAuthenticated$
      .subscribe(response => {
        this.loading = false;
        if (response) {
          this.router.navigate(['/menu']);
        }
      });
  }

  logout() {
    this.alertService.confirm('Are you sure you want to logout?', 'Logout', 'Yes', 'No').subscribe(res => {
        if (res) { this.auth.logout(); }
    });
  }
}
