import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../models/User';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { AllowedSite } from '../../models/AllowedSite';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-site-role-editor',
  templateUrl: './user-site-role-editor.component.html',
  styleUrls: ['./user-site-role-editor.component.scss']
})
export class UserSiteRoleEditorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserSiteRoleEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private fb: FormBuilder, private userService: UserService,
    private alertService: AlertService) { }

  get roles(): FormArray {
    return this.roleForm.get('allowedSites') as FormArray;
  }
  public roleForm: FormGroup;
  public addMode = false;

  ngOnInit(): void {
    this.roleForm = this.fb.group({
      defaultSiteId: [],
      allowedSites: this.fb.array(this.parseModelToFormGroup())
    });
  }

  private createRolesFormGroup(siteId: number = 0, siteName: string = '',
                               roleName: string = '', defaultSite: boolean = false): FormGroup {
    return this.fb.group({
      siteId: [siteId, Validators.required],
      siteName: [siteName, Validators.required],
      roleName: [roleName, Validators.required],
      defaultSite: [defaultSite]
    });
  }

  /* parse model to form array contols */
  private parseModelToFormGroup(): Array<FormGroup> {
    return this.user.allowedSites.map(siteRole => {
      return this.createRolesFormGroup(
        siteRole.siteId,
        siteRole.siteName,
        siteRole.roleName,
        this.user.defaultCementSiteID === siteRole.siteId
      );
    });
  }

  /**
   * parse form control arrays to AllowedSite
   */
  private parseFormToModel(): void {
    this.user.allowedSites = this.roles.controls.map(item =>
      new AllowedSite(item.value.siteId, item.value.siteName, item.value.roleName)
    );
  }


  /*
  * change default site.
  */
  changeDefautSite(item: FormGroup): void {
    this.roleForm.markAsDirty();

    this.user.defaultCementSiteID = item.value.siteId;
    if (this.roles.controls.length > 1) {
      this.roles.controls.forEach((a: FormGroup) => {
        if (item.controls.siteId.value !== a.controls.siteId.value) {
          a.controls.defaultSite.setValue(false);
        }
      });
    }
    item.controls.defaultSite.setValue(true);
  }

  /*
   * Add new site role to collection
   */
  addSiteRole(allowedSite: AllowedSite): void {
    if (this.roles.value.find((a: { siteName: string; }) => a.siteName === allowedSite.siteName)) {
      this.alertService.alertError('"' + allowedSite.siteName + '" already exists.', 'Duplicate site');
      return;
    }

    this.roleForm.markAsDirty();
    this.roles.push(this.createRolesFormGroup(allowedSite.siteId, allowedSite.siteName, allowedSite.roleName));

    if (this.roles.controls.length === 1) {
      const frm = this.roles.controls[0] as FormGroup;
      frm.controls.defaultSite.setValue(true);
      this.user.defaultCementSiteID = this.roles.value[0].siteId;
    }

    this.addMode = false;
  }

  /*
   * delete site role from collection
   */
  deleteSiteRole(idx: number) {
    this.roleForm.markAsDirty();
    const isRemovingDefault = this.roles.at(idx).value.defaultSite;
    this.roles.removeAt(idx);

    // if we are removing default site then auto set it to the first site in the list.
    if (isRemovingDefault && this.roles.controls.length !== 0) {
      const frm = this.roles.controls[0] as FormGroup;
      frm.controls.defaultSite.setValue(true);
      this.user.defaultCementSiteID = this.roles.value[0].siteId;
    }
  }

  /*
   * submit to api
   */
  submit() {
    this.parseFormToModel();
    this.userService.updateRole(this.user).subscribe(res => {
      this.dialogRef.close();
      this.alertService.success('User updated successfully');
    }, () => {
      this.alertService.danger('Error saving user');
    });
  }

  /*
  cancel all changes and close form.
  */
  cancel() {
    if (this.roleForm.dirty) {
      this.alertService.confirm('You have unsaved changes. Are you sure you want to cancel changes?', 'Cancel Changes?', 'Yes', 'No')
        .subscribe(res => this.dialogRef.close());
    } else {
      this.dialogRef.close();
    }
  }
}
