import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Site} from '../models/CustomerSite';
import {environment} from '../../../environments/environment';
import {Silo} from '../models/Silo';

@Injectable({
  providedIn: 'root'
})
export class CustomerSiteService {

  constructor(private readonly http: HttpClient) { }

  updateSilos(silos: Array<Silo>): Observable<Array<Silo>> {
    return this.http.patch<Array<Silo>>(environment.apiBaseUrl + 'silos', silos);
  }

  getSiteInformation(gTickId: number): Observable<Site> {
    return this.http.get<Site>(environment.apiBaseUrl + `cement/sites/gtick`, {
      params: {
        gTickId: `${gTickId}`
      }
    });
  }
}
