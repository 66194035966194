import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatFormFieldModule, MatInputModule, MatSidenavModule } from '@angular/material';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  imports: [
    MatExpansionModule, MatSnackBarModule, MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule, MatListModule,
    MatIconModule, MatButtonModule, MatCardModule, MatDividerModule, MatMenuModule, MatToolbarModule,
    MatFormFieldModule, MatTableModule, MatInputModule, MatSidenavModule, MatRadioModule, MatAutocompleteModule, MatSelectModule,
    MatTooltipModule
  ],
  exports: [MatExpansionModule, MatSnackBarModule, MatDialogModule, MatProgressBarModule, MatProgressSpinnerModule,
    MatListModule, MatIconModule, MatButtonModule, MatCardModule, MatDividerModule, MatMenuModule, MatToolbarModule,
    MatFormFieldModule, MatTableModule, MatInputModule, MatSidenavModule, MatRadioModule, MatAutocompleteModule, MatSelectModule,
    MatTooltipModule
  ]
})
export class MaterialModule { }
