import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AlertService} from '../../../shared/services/alert.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Customer} from '../../models/Customer';
import {CustomerService} from '../../services/customer.service';

@Component({
  selector: 'app-customerdetailsedit',
  templateUrl: './customerdetailsedit.component.html',
  styleUrls: ['./customerdetailsedit.component.scss']
})
export class CustomerdetailseditComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CustomerdetailseditComponent>,
    @Inject(MAT_DIALOG_DATA) public customer: Customer,
    private fb: FormBuilder,
    private alertService: AlertService,
    private customerService: CustomerService) { }


    private customerEditForm: FormGroup;

  cancel() {
    if (this.customerEditForm.dirty) {
      this.alertService.confirm('You have unsaved changes. Are you sure you want to cancel changes?', 'Cancel Changes?', 'Yes', 'No')
        .subscribe(() => this.dialogRef.close());
    } else {
      this.dialogRef.close();
    }
  }

  submit() {
    this.parseFormToModel();
    this.customerService.updateCustomer(this.customer).subscribe(() => {
      this.dialogRef.close();
      this.alertService.success('Customer updated successfully');
    }, () => {
      this.alertService.danger('Error saving customer');
    });
  }

  parseFormToModel() {
    this.customer.gTickID = this.customerEditForm.get('gTickId').value;
    this.customer.name = this.customerEditForm.get('customerName').value;
  }


  ngOnInit() {
    this.customerEditForm = this.fb.group({
      customerName: [this.customer.name, Validators.required],
      gTickId: [this.customer.gTickID, Validators.required]
    });
  }

}
