import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CallbackComponent } from './Authentication/callback/callback.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from './header/header.module';
import { InterceptorService } from './Authentication/TokenInterceptor';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from './shared/modules/material.module';
import { UserModule } from './users/user.module';
import { SitesModule } from './sites/sites.module';
import { SharedModule } from './shared/shared.module';
import { CustomersModule } from './customers/customers.module';


@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HeaderModule,
    MaterialModule,
    UserModule,
    SitesModule,
    SharedModule,
    CustomersModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
