import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { MaterialModule } from './modules/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DisableFeatureLinkDirective } from './directives/disable-feature-link.directive';
import { MenuComponent } from './components/menu/menu.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ AlertDialogComponent,
    ConfirmDialogComponent,
    SpinnerComponent,
    DisableFeatureLinkDirective,
    MenuComponent],
    entryComponents: [ConfirmDialogComponent, AlertDialogComponent],
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
    RouterModule
  ],
  exports: [ConfirmDialogComponent, AlertDialogComponent, SpinnerComponent, DisableFeatureLinkDirective]
})
export class SharedModule { }
