import { AllowedSite } from './AllowedSite';
import { User } from './User';

export class RoleUpdateModel {
  constructor(public userId: number, public defaultSiteId: number, public allowedSites: Array<AllowedSite>) {}

  static  createObjFromUser(user: User): RoleUpdateModel {
    return new RoleUpdateModel(user.userID, user.defaultCementSiteID, user.allowedSites);
  }
}
