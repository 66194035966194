import { Injectable } from '@angular/core';
import { Customer } from '../models/Customer';
import { Silo } from '../models/Silo';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CustomerSetup } from '../models/CustomerSetup';

@Injectable()
export class CustomerwizardService {

  constructor(private readonly http: HttpClient) {
  }

  public startSiteCreation(customer: Customer, silos: Array<Silo>, address: string): Observable<Customer> {

    const newCustomer: CustomerSetup = {
      address,
      customerDetails: customer,
      silos
    };

    return this.http.post<Customer>(environment.apiBaseUrl + 'customerwizard', newCustomer);
  }
}

