import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../Authentication/auth.guard';
import { CustomerlistComponent } from './components/customerlist/customerlist.component';
import {CustomeraddComponent} from './components/customeradd/customeradd.component';

const routes: Routes = [
  {
    path: 'customers',
    component: CustomerlistComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customers/add',
    component: CustomeraddComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
