import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {Site} from '../../models/CustomerSite';
import {CustomerSiteService} from '../../services/customer-site.service';
import {combineLatest} from 'rxjs';
import {ProductsService} from '../../../shared/services/products.service';
import {Product} from '../../../shared/models/Product';
import {Silo} from '../../models/Silo';

@Component({
  selector: 'app-customersiloedit',
  templateUrl: './customersiloedit.component.html',
  styleUrls: ['./customersiloedit.component.scss']
})
export class CustomersiloeditComponent implements OnInit {

  isLoading = false;
  siteInformation: Site;
  products: Array<Product>;
  siloForm: FormGroup;
  silos: FormArray;

  constructor(public dialogRef: MatDialogRef<CustomersiloeditComponent>,
              @Inject(MAT_DIALOG_DATA) public gTickId: number,
              private fb: FormBuilder,
              private alertService: AlertService,
              private customerSiteService: CustomerSiteService,
              private productService: ProductsService) { }

  ngOnInit() {
    this.isLoading = true;
    this.setupForm();

    combineLatest(this.customerSiteService.getSiteInformation(this.gTickId),
      this.productService.getProducts()).subscribe(([site, prods]) => {
      this.siteInformation = site;
      this.products = prods;
      this.isLoading = false;

      this.siteInformation.silos.forEach(silo => this.createSilo(silo.name, silo.products[0].productCode, silo.id));
    }, error => {
      console.error(error);
      this.dialogRef.close();
      this.alertService.alertError('There was an error retrieving the site and product information', 'Loading error');
    });
  }

  createSilo(siloName: string = '', siloProduct: string = '', siloId: number = 0): void {
    this.silos.push(this.createSiloFormItem(siloName, siloProduct, siloId));
  }

  private setupForm(): void {
    this.silos = this.fb.array([]);
    this.siloForm = this.fb.group({
      silos: this.silos
    });
  }

  private createSiloFormItem(siloName: string = '', siloProduct: string = '', siloId: number): FormGroup {
    return this.fb.group({
      siloName: [siloName, Validators.required],
      product: [siloProduct, Validators.required],
      id: [siloId, Validators.required]
    });
  }

  removeSilo() {
    // do not remove the last silo as every site needs at least one.
    if (this.silos.length !== 1) { this.silos.removeAt(this.silos.length - 1); }
    this.siloForm.markAsDirty();
  }

  submitForm() {
    const silos: Array<Silo> = this.parseFormToArray();

    this.isLoading = true;

    this.customerSiteService.updateSilos(silos).subscribe(val => {
      this.isLoading = false;
      this.dialogRef.close();
      this.alertService.success('Silos updated successfully');
    }, error => {
      this.isLoading = false;
      console.error(error);
      this.alertService.danger('There was an error updating the silos');
    });
  }

  private parseFormToArray(): Array<Silo> {
    const silos: Array<Silo> = [];

    this.siloForm.get('silos').value.forEach(formSilos => {
      const newSilo: Silo = {
        id: formSilos.id,
        name: formSilos.siloName,
        products: [{
          productCode: formSilos.product,
          productID: this.products.find(prod => prod.productCode === formSilos.product).productID
        }]
      };

      silos.push(newSilo);
    });

    return silos;
  }

  cancel() {
    if (this.siloForm.dirty) {
      this.alertService.confirm('You have unsaved changes. Are you sure you want to cancel?', 'Cancel Changes?', 'Yes', 'No')
        .subscribe(() => this.dialogRef.close());
    } else {
      this.dialogRef.close();
    }
  }

}
