import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../Authentication/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  username = '';

  constructor(private readonly auth: AuthService) {
    this.auth.userProfile$.subscribe(usr => this.username = `, ${usr.nickname}`);
  }

  ngOnInit() {
  }

}
