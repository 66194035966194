import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import { catchError, shareReplay } from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Customer } from '../models/Customer';

@Injectable()
export class CustomerService {

  constructor(private readonly http: HttpClient) { }

  private static handleError(err: HttpErrorResponse | any) {
    console.error('An error occured', err);
    return throwError(err.message || err);
  }

  getCustomers(): Observable<Array<any>> {
    return this.http.get<Array<Customer>>(environment.apiBaseUrl + 'customers').
    pipe(shareReplay(), catchError(CustomerService.handleError));
  }

  updateCustomer(customerToUpdate: Customer): Observable<Customer> {
    return this.http.put<Customer>(environment.apiBaseUrl + 'customers', customerToUpdate).
      pipe(shareReplay(), catchError(CustomerService.handleError));
  }



}
