export class AlertDialogData {

  constructor(public message: string, public title: string, public status: AlertDialogOption) {}

  public static setError(message: string, title: string): AlertDialogData {
    return new AlertDialogData(message, title, AlertDialogOption.error);
  }

  public static setInfo(message: string, title: string): AlertDialogData {
    return new AlertDialogData(message, title, AlertDialogOption.info);
  }

  public static setWarning(message: string, title: string): AlertDialogData {
    return new AlertDialogData(message, title, AlertDialogOption.warning);
  }
  public static setSuccess(message: string, title: string): AlertDialogData {
    return new AlertDialogData(message, title, AlertDialogOption.success);
  }

  isError(): boolean {
    return this.status === AlertDialogOption.error;
  }
}

export enum AlertDialogOption {
  error = 'danger',
  info = 'info',
  success = 'success',
  warning = 'warning'
}
