import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit  {
  @Input() loggedIn: boolean;
  @Output() logoutClicked: EventEmitter<boolean> = new EventEmitter<boolean> ();

  constructor() {
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.logoutClicked.emit(true);
  }
}
