import {Component, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { tap } from 'rxjs/operators';
import { User } from '../../models/User';
import { UserSiteRoleEditorComponent } from '../user-site-role-editor/user-site-role-editor.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  dataSource: any;
  displayedColumns: string[] = ['picture', 'name', 'nickName', 'email', 'id'];
  loading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private userService: UserService, public dialog: MatDialog) { }

  ngOnInit() {
    this.loading = true;
    this.userService.getUsers()
      .pipe(tap(() => this.loading = true))
      .subscribe(users => {
        this.dataSource = new MatTableDataSource(users);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openRolesDialog(selectedUser: User) {
    const dialogRef = this.dialog.open(UserSiteRoleEditorComponent, {
      width: '1000px',
      data: selectedUser,
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
}
