import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerwizardService } from '../../services/customerwizard.service';
import { Customer } from '../../models/Customer';
import {Silo} from '../../models/Silo';
import {MatStepper} from '@angular/material/stepper';
import {ProductsService} from '../../../shared/services/products.service';
import {Product} from '../../../shared/models/Product';

@Component({
  selector: 'app-customeradd',
  templateUrl: './customeradd.component.html',
  styleUrls: ['./customeradd.component.scss']
})
export class CustomeraddComponent implements OnInit {

  @ViewChild('customerSetupStepper', {static: true}) private customerSetupWizardStepper: MatStepper;

  customerInfoForm: FormGroup;
  addressForm: FormGroup;
  siloInformationForm: FormGroup;

  silos: FormArray;
  productsList: Array<Product>;

  constructor(private formBuilder: FormBuilder, private readonly customerWizard: CustomerwizardService,
              private readonly products: ProductsService) {
    this.products.getProducts().subscribe(prods => {
      this.productsList = prods;
    });
  }

  createSiloFormItem(): FormGroup {
    return this.formBuilder.group({
      siloName: ['', Validators.required],
      product: ['', Validators.required]
    });
  }

  createSilo(): void {
    this.silos = this.siloInformationForm.get('silos') as FormArray;
    this.silos.push(this.createSiloFormItem());
  }

  startCreate(): void {

    const siloArray: Array<Silo> = [];

    this.siloInformationForm.get('silos').value.forEach(formSilos => {
      const newSilo: Silo = {
        id: undefined,
        name: formSilos.siloName,
        products: [{
          productCode: formSilos.product,
          productID: undefined
        }]
      };

      siloArray.push(newSilo);
    });

    const cust: Customer = {
      gTickID: this.customerInfoForm.get('gTickId').value,
      name: this.customerInfoForm.get('customerName').value,
      customerID: undefined,
      settings: {
        overnightOrders: true,
        homeRun: false,
        stabilisation: false,
        tanker: true
      }
    };

    this.customerWizard.startSiteCreation(cust, siloArray, this.addressForm.get('siteAddress').value).subscribe(() => {
      this.customerSetupWizardStepper.next();
    }, error => {
      console.error(error);
    });
  }

  removeSilo() {
    // do not remove the last silo as every site needs at least one.
    if (this.silos.length !== 1) { this.silos.removeAt(this.silos.length - 1); }
  }

  ngOnInit() {
    this.customerInfoForm = this.formBuilder.group({
      customerName: ['', Validators.required],
      gTickId: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
    });
    this.addressForm = this.formBuilder.group({
      siteAddress: ['', Validators.required]
    });

    this.siloInformationForm = this.formBuilder.group({
      silos: this.formBuilder.array([this.createSiloFormItem()]),
    });
  }

}
